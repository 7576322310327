import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import { BsChevronRight, BsChevronLeft } from "react-icons/bs"

import ClientLink from "../atoms/clientLink"
import PreviewWarning from "../atoms/userFeedback/PreviewWarning"

/**
 * Component for shop and restaurant items
 * @param title
 * @param category
 * @param text
 * @param images
 * @param email
 * @param phone
 * @param opening_hours
 * @param menus
 * @param features
 * @param sortChange
 * @constructor
 */
const ShopRestaurant = ({
  title,
  category,
  text,
  images,
  email,
  phone,
  opening_hours,
  menus,
  features,
  sortChange,
}) => {
  const [current, setCurrent] = useState<number>(0)
  const end = images.length - 1

  useEffect(() => {
    setCurrent(0)
  }, [sortChange])

  const previous = () => {
    current === 0 ? setCurrent(end) : setCurrent(current - 1)
  }
  const next = () => {
    current === end ? setCurrent(0) : setCurrent(current + 1)
  }
  const hasImages = !!images[0].image.fluid
  const hasContactDetails = !!email.text || !!phone.text
  return (
    <Container>
      <div>
        {!hasImages ? (
          <PreviewWarning section="Shop or Restaurant" />
        ) : (
          <Image fluid={images[current].image.fluid} alt="food">
            {images.length > 1 ? (
              <>
                <Left onClick={previous} />
                <Right onClick={next} />
              </>
            ) : null}
          </Image>
        )}
      </div>

      <Header>
        <div style={{ margin: "16px 0" }}>
          <Title>{title.text}</Title>
          <Category>{category.text}</Category>
        </div>
        {features ? (
          <FeaturesContainer>
            {features.map((feature, i) => (
              <FeatureImage
                key={i}
                // @ts-ignore - https://github.com/gatsbyjs/gatsby/discussions/28212
                fixed={feature.feature_icon.fixed}
                alt={feature.feature_icon.alt || "feature"}
              />
            ))}
          </FeaturesContainer>
        ) : null}
      </Header>
      <Text>{text.text}</Text>

      <ShopInformation>
        <InfoTitle>Opening Hours</InfoTitle>
        {hasContactDetails ? <InfoTitle>Contact</InfoTitle> : <div />}
        <OpeningHoursContainer>
          {opening_hours.map((opening, i) => {
            return (
              <div key={i}>
                <Text>{opening.day.text}</Text>
                <Text>{opening.hour.text}</Text>
              </div>
            )
          })}
        </OpeningHoursContainer>
        {hasContactDetails ? (
          <ContactContainer>
            {email.text ? <ClientLink type="mail" value={email.text} /> : null}
            {phone.text ? <ClientLink type="phone" value={phone.text} /> : null}
          </ContactContainer>
        ) : null}
      </ShopInformation>

      {menus ? (
        <MenuContainer>
          {menus.map(({ menu, menu_title }, i) => (
            <MenuButton
              key={i}
              href={menu.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {menu_title.text}
            </MenuButton>
          ))}
        </MenuContainer>
      ) : null}
    </Container>
  )
}

export default ShopRestaurant

const Container = styled.div`
  padding: 40px 0;
`

const Image = styled(BackgroundImage)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 360px;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 8px 0;
`
const Title = styled.h2`
  font-family: "Moderat Medium", sans-serif;
  color: var(--primary-text);
  margin: 4px 0;
`
const Category = styled.span`
  font-family: "Canela Light Italic", sans-serif;
  //font-weight: bold;
  color: var(--primary-text);
`
const Text = styled.p`
  font-family: "Moderat Regular", sans-serif;
  color: var(--primary-text);
  line-height: 1.4rem;
`
const ShopInformation = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`
const ContactContainer = styled.div``
const OpeningHoursContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max-content, 120px));
  grid-gap: 8px;
`
const InfoTitle = styled.span`
  margin: 16px 0 8px 0;
  font-family: "Moderat Bold", sans-serif;
  color: var(--primary-text);
`
const Left = styled(BsChevronLeft)`
  font-size: 40px;
  color: white;
  margin: 8px;
  transition: margin 200ms ease;
  cursor: pointer;
  :hover {
    margin: 0;
  }
`
const Right = styled(BsChevronRight)`
  font-size: 40px;
  color: white;
  margin: 8px;
  transition: margin 200ms ease;
  cursor: pointer;
  :hover {
    margin: 0;
  }
`
const FeaturesContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 16px;
`

const MenuContainer = styled.div`
  padding: 24px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min-content, 157px));
  grid-gap: 16px;
`

const MenuButton = styled.a`
  border: 1px solid var(--primary-text);
  text-decoration: none;
  color: var(--primary-text);
  font-family: "Moderat Medium", sans-serif;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 157px;
  height: 39px;
  transition: all 0.2s ease-in-out;
  outline-style: none;
  :hover {
    background: var(--primary-text);
    color: white;
  }
`

const FeatureImage = styled(Img)`
  max-height: 30px;
  max-width: 25px;
`
