import { graphql, useStaticQuery } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

export const useRestaurantsData = previewData => {
  const staticData = useStaticQuery(
    graphql`
      query {
        prismicRestaurantsPage {
          _previewable
          uid
          data {
            title {
              html
            }
            text {
              text
            }
            tagline {
              text
            }
            filter_options {
              filter_option
            }
            body {
              ... on PrismicRestaurantsPageBodyBook {
                slice_type
                primary {
                  text {
                    text
                  }
                  button_text
                  link {
                    url
                    link_type
                  }
                }
              }
            }
          }
        }
        allPrismicRestaurant {
          nodes {
            uid
            data {
              title {
                text
              }
              text {
                text
              }
              sorting_types {
                sort_type
              }
              opening_hours {
                day {
                  text
                }
                hour {
                  text
                }
              }
              menus {
                menu {
                  url
                }
                menu_title {
                  text
                }
              }
              email {
                text
              }
              phone {
                text
              }
              category {
                text
              }
              features {
                feature_icon {
                  fixed(width: 25) {
                    height
                    src
                    srcSet
                    width
                  }
                  alt
                }
              }
              images {
                image {
                  alt
                  fluid {
                    aspectRatio
                    sizes
                    src
                    srcSet
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const mergedData: any = mergePrismicPreviewData({
    staticData,
    previewData,
    strategy: "rootReplaceOrInsert",
  })
  const { data, uid } = mergedData.prismicRestaurantsPage
  const restaurants = mergedData.allPrismicRestaurant.nodes.map(
    node => node.data
  )
  if (mergedData.prismicRestaurant) {
    restaurants.push(mergedData.prismicRestaurant.data)
  }
  const bookData = data.body.find(section => section.slice_type === "book")

  return {
    data,
    uid,
    restaurants,
    bookData: bookData ? bookData.primary : undefined,
  }
}
