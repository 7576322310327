import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { withPreview } from "gatsby-source-prismic"

import PageHeader from "../components/pageHeader"
import SEO from "../components/seo"
import ShopRestaurant from "../components/molecules/shopRestaurant"
import { ShopsRestaurantsContainer } from "./shops"
import Layout from "../components/layout/layout"
import Filter from "../components/atoms/filter"

import { useRestaurantsData } from "../hooks/useRestaurantsData"

const Restaurants = ({ data: previewData }) => {
  const { uid, data, restaurants, bookData } = useRestaurantsData(previewData)
  const [sortKey, setSortKey] = useState<string>("0")
  const [currentRestaurants, setCurrentRestaurants] = useState(restaurants)

  const noResults = currentRestaurants.length === 0
  const sortingTypes = restaurants
    .map(rest => rest.sorting_types.map(type => type.sort_type))
    .flat()
  const navItems = [...new Set(sortingTypes)] as Array<string>
  navItems.unshift("all")

  useEffect(() => {
    if (navItems[sortKey] === "all") {
      return setCurrentRestaurants(restaurants)
    }
    const filteredRestaurants = []
    restaurants.forEach(restaurant => {
      const filterApplies =
        restaurant.sorting_types
          .map(sort_type => sort_type.sort_type)
          .includes(navItems[sortKey]) || navItems[sortKey] === "all"
      if (filterApplies) {
        filteredRestaurants.push(restaurant)
      }
    })
    setCurrentRestaurants(filteredRestaurants)
  }, [sortKey])
  return (
    <Layout bookBannerData={bookData}>
      <Container>
        <SEO title={uid} />
        <PageHeader {...data} />
        <Filter options={navItems} onChange={setSortKey} breakpoint="620px" />
        <NavContainer>
          {navItems.map((nav, i) => (
            <NavItem
              id={nav}
              isActive={navItems[sortKey] === nav}
              key={nav}
              onClick={() => setSortKey(i.toString())}
            >
              {nav}
            </NavItem>
          ))}
        </NavContainer>
        {noResults ? (
          <NoResults>No results..</NoResults>
        ) : (
          <ShopsRestaurantsContainer>
            {currentRestaurants.map((restaurant, i) => (
              <ShopRestaurant sortChange={sortKey} key={i} {...restaurant} />
            ))}
          </ShopsRestaurantsContainer>
        )}
      </Container>
    </Layout>
  )
}

export default withPreview(Restaurants)

const Container = styled.div``

const NavContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 80px 0;
  height: 25px;
  @media (max-width: 620px) {
    display: none;
  }
`
const NavItem = styled.p<{ isActive: boolean }>`
  text-transform: uppercase;
  color: var(--primary-text);
  font-family: "Moderat Medium", sans-serif;
  margin: 0 24px;
  padding: 0 4px;
  &:hover {
    cursor: pointer;
    border-bottom: 1px solid var(--primary-text);
  }
  border-bottom: ${({ isActive }) =>
    isActive ? "1px solid var(--primary-text)" : "none"};
  @media (max-width: 750px) {
    margin: 0 12px;
  }
  @media (max-width: 530px) {
    margin: 0 8px;
  }
`

const NoResults = styled.p`
  color: var(--primary-text);
  font-family: "Moderat Medium", sans-serif;
  text-align: center;
  padding: 40px 0;
`
