import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { useShopsData } from "../hooks/useShopsData"
import PageHeader from "../components/pageHeader"
import styled from "styled-components"
import ShopRestaurant from "../components/molecules/shopRestaurant"
import { withPreview } from "gatsby-source-prismic"

const Shops = ({ data: previewData }) => {
  const { uid, data, shops, bookData } = useShopsData(previewData)

  return (
    <Layout bookBannerData={bookData}>
      <SEO title={uid} />
      <PageHeader {...data} />
      <ShopsRestaurantsContainer>
        {shops.map((shop, i) => {
          return <ShopRestaurant key={i} {...shop} />
        })}
      </ShopsRestaurantsContainer>
    </Layout>
  )
}
export default withPreview(Shops)

export const ShopsRestaurantsContainer = styled.div`
  padding: 0 0 80px 0;
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 500px));
  grid-gap: 40px 80px;
  justify-content: center;
`
