import { graphql, useStaticQuery } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

export const useShopsData = previewData => {
  const staticData = useStaticQuery(
    graphql`
      query {
        prismicShopsPage {
          _previewable
          uid
          data {
            title {
              html
            }
            text {
              text
            }
            tagline {
              text
            }
            body {
              ... on PrismicShopsPageBodyBook {
                id
                slice_type
                primary {
                  button_text
                  link {
                    url
                    link_type
                  }
                  text {
                    text
                  }
                }
              }
            }
          }
        }
        allPrismicShop {
          nodes {
            uid
            data {
              images {
                image {
                  fluid {
                    aspectRatio
                    sizes
                    src
                    srcSet
                  }
                }
              }
              title {
                text
              }
              text {
                text
              }
              opening_hours {
                hour {
                  text
                }
                day {
                  text
                }
              }
              email {
                text
              }
              phone {
                text
              }
              category {
                text
              }
            }
          }
        }
      }
    `
  )
  const mergedData: any = mergePrismicPreviewData({
    staticData,
    previewData,
    strategy: "rootReplaceOrInsert",
  })
  const { data, uid } = mergedData.prismicShopsPage
  const bookData = data.body[0] ? data.body[0].primary : undefined
  const shops = mergedData.allPrismicShop.nodes.map(node => node.data)
  if (mergedData.prismicShop) {
    shops.push(mergedData.prismicShop.data)
  }

  return { data, uid, shops, bookData }
}
